<template>
  <div>
    <div class="d-flex justify-content-end">
      <feather-icon
        v-if="organizationRequiresEditConfirmation"
        v-b-tooltip.noninteractive.hover.bottom
        :title="$t('Edit')"
        :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
        size="36"
        class="ml-1 cursor-pointer"
        @click="isEditAble()"
      />
    </div>
    <b-form>
      <error-alert
        :fields="subRentalFields"
        :error="error"
      />
      <validation-observer
        ref="subRentalUpdateVal"
      >
        <sub-rental-templates :is-edit="isEdit" />
        <portal to="body-footer">
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <b-button
                v-if="!isFormChanged"
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="$router.push({ name: 'settings' })"
              >
                {{ $t('Back to Setting') }}
              </b-button>
              <b-button
                v-else
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="loader"
              >
                <feather-icon
                  icon="LCancelIcon"
                  size="16"
                />
                {{ $t('Cancel') }}
              </b-button>
            </div>
            <div>
              <b-button
                variant="success"
                class="saveBtn font-medium-1 font-weight-bolder"
                :disabled="!isFormChanged"
                @click="submit"
              >
                {{ $t('SAVE') }}
              </b-button>
            </div>
          </div>
        </portal>
      </validation-observer>
    </b-form>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
</template>
<script>
import { BButton, BForm, VBTooltip } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { required } from '@/libs/validations/validations'
import config from '@/views/settings/orders/sub-rental/subRentalConfig'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import { scrollToError } from '@core/utils/utils'
import SubRentalTemplates from '@/views/settings/orders/sub-rental/components/SubRentalTemplates.vue'
import store from '@/store'

export default {
  name: 'SubRentalForm',
  components: {
    ErrorAlert,
    SubRentalTemplates,
    BButton,
    BForm,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      error: {},
      required,
      onSubmit: false,
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    subRental() {
      return this.$store.state[this.MODULE_NAME].subRentalForm
    },
    subRentalFormClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].settingsSubRentalFormClone
    },
    isFormChanged() {
      return JSON.stringify(this.subRental) !== this.subRentalFormClone
    },
  },
  methods: {
    isEditAble() {
      this.isEdit = !this.isEdit
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },

    submit() {
      this.error = {}
      this.onSubmit = true

      this.$refs.subRentalUpdateVal.validate()
        .then(success => {
          if (success) {
            const data = this.subRental
            const subRental = this.mappingFields(Object.keys(this.subRentalFields), data, {
              setting_orders_sub_rental_template_email: String(data.setting_orders_sub_rental_template_email),
              setting_orders_sub_rental_template_pdf: String(data.setting_orders_sub_rental_template_pdf),
            })
            this.sendNotification(this, subRental, `${this.MODULE_NAME}/updateSubRental`)
              .then(() => {
                this.$emit('refetch')
              })
              .catch(err => {
                this.error = err.response?.data
              }).finally(() => {
                this.onSubmit = false
              })
          } else {
            scrollToError(this, this.$refs.customerSettingsCreateVal)
          }
        })
    },
    loader() {
      this.$refs.subRentalUpdateVal.reset()
      const smth = JSON.parse(this.subRentalFormClone)
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, smth)
    },
    getProps(fieldName) {
      return {
        field: this.subRentalFields[fieldName],
        name: fieldName,
      }
    },
  },
  setup() {
    const { subRentalFields, MODULE_NAME, MODULE_NAME_CLONE } = config()

    return {
      subRentalFields,
      MODULE_NAME,
      MODULE_NAME_CLONE,
    }
  },
}
</script>
