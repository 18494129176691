<template>
  <div>
    <skeleton v-if="loading" />
    <sub-rental-form
      v-else
      ref="sub-rental-form"
      @refetch="refetch"
    />
  </div>
</template>

<script>
import subRentalForm from '@/views/settings/orders/sub-rental/components/SubRentalForm.vue'
import store from '@/store'
import subRentalModule from '@/store/settings/orders/sub-rental'
import { onUnmounted } from '@vue/composition-api'
import Skeleton from '@/views/settings/orders/sub-rental/components/Skeleton.vue'
import config from '@/views/settings/orders/sub-rental/subRentalConfig'

export default {
  name: 'SubRental',
  components: { Skeleton, subRentalForm },
  data() {
    return {
      loading: false,
    }
  },
  created() {
    console.log('GALAMi')
    this.$emit('updateMenu', 'settings-orders-sub-rental')
    this.refetch()
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['sub-rental-form']) this.$refs['sub-rental-form'].showConfirmationModal(next)
  },
  methods: {
    refetch() {
      this.loading = true
      this.$store.dispatch(`${this.MODULE_NAME}/getSubRental`).then(res => {
        const { data } = res.data
        const template = {
          setting_orders_sub_rental_template_email: data.setting_orders_sub_rental_template_email,
          setting_orders_sub_rental_template_pdf: data.setting_orders_sub_rental_template_pdf,
        }
        this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, template)
        this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SUB_RENTAL_ORDERS_CLONE`, template)
      }).catch(err => {
        this.error = true
        this.errorNotification(this, err)
      }).finally(() => {
        this.loading = false
      })
    },
  },
  setup() {
    const { MODULE_NAME, MODULE_NAME_CLONE, subRentalFields } = config()
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, subRentalModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      subRentalFields,
    }
  },
}

</script>
