import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'

const endpoint = 'settings/order/sub-rental'

const Actions = crudActions(endpoint)
const getSubRental = (ctx, data) => axios.get(`${endpoint}`, data)
const updateSubRental = (ctx, data) => axios.post(`${endpoint}`, data)
export default {
  ...Actions,
  getSubRental,
  updateSubRental,
}
