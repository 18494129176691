<template>
  <div>
    <h2
      class="font-weight-bolder font-medium-4 mt-2"
    >
      {{ $t('Templates') }}
    </h2>
    <b-row>
      <b-col md="12">
        <div
          v-for="field in ['setting_orders_sub_rental_template_email', 'setting_orders_sub_rental_template_pdf']"
          :key="field"
          style="height: 170px; margin-bottom: 10px"
        >
          <span
            class="font-weight-bolder d-block"
            style="margin-bottom: 5px; margin-left: 10px"
          >{{ subRentalFields[field].label }}</span>
          <b-skeleton
            height="150px"
            style="border-radius: 5px"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BCol, BRow, BSkeleton } from 'bootstrap-vue'
import config from '../subRentalConfig'

export default {
  name: 'SubRentalSkeleton',
  components: { BRow, BCol, BSkeleton },
  setup() {
    const { subRentalFields } = config()
    return {
      subRentalFields,
    }
  },
}
</script>
